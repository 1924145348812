import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { AboutHeader, AboutLinksBlocks } from "../components/about"
import { mdiSineWave } from '@mdi/js'

function GrantsPage() {

    const headerTexts = [
        `This page gives an overview of accepted grants Waves. Keep in mind 
        that not all of the accepted grants have already delivered software.`
    ];

    const grantLinksBlocks = [
        {
            icon: mdiSineWave,
            title: "Grant Wawes",
            links: [
            ]
        },
    ]

    return (
        <Layout>
            <SEO title="Grants" />
            <div className="mgt-medium column page-content about-page">
                <AboutHeader title="W3F Grant Projects" content={headerTexts} />
                <AboutLinksBlocks aboutLinksBlocks={grantLinksBlocks} />
            </div>
        </Layout>
    )
}

export default GrantsPage
